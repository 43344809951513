import {
  Component,
  Vue,
} from 'vue-property-decorator';
import PageLayout from '@/layout/Page.vue';
import ImageCarousel from '@/components/ImageCarousel.vue';
import YandexMapWrapper from '@/components/YandexMapWrapper.vue';
import HostelPrice from '@/components/HostelPrice.vue';
import HostelContacts from '@/components/HostelContacts.vue';
import Description from './components/Description.vue';
import Services from './components/Services.vue';
import CheckInformation from './components/CheckInformation.vue';
import Comments from './components/Comments.vue';
import {
  BreadcrumbInterface,
} from '@/layout/types';
import hostels from '@/assets/hostels.json';
import services from '@/assets/services.json';
import type {
  HostelInterface,
  AdditionalServiceInterface,
  MarkerItem,
} from '@/types';

interface Link {
  rel: string
  href: string
}

@Component({
  components: {
    PageLayout,
    ImageCarousel,
    Description,
    HostelPrice,
    Services,
    HostelContacts,
    YandexMapWrapper,
    CheckInformation,
    Comments,
  },
  metaInfo(this: Hostel) {
    const image = `https://${process.env.VUE_APP_DOMAIN}${this.hostel.images[0]}`;
    const description = this.hostel.description.split('</p>')[0].replace('<p>', '');
    return {
      title: this.$route.meta?.metaInfo.title.replace('{{title}}', this.hostel.title),
      meta: [
        ...this.$route.meta?.metaInfo.meta,
        { vmid: 'description', name: 'description', content: description },
        { vmid: 'og:description', name: 'description', content: description },
        { vmid: 'og:image', name: 'og:image', content: image },
        { vmid: 'og:image:secure_url', name: 'og:image:secure_url', content: image },
      ],
      link: this.$route.meta?.metaInfo.link.map((link: Link) => ({
        rel: link.rel,
        href: link.href.replace('{{slug}}', this.$route.params.slug),
      })),
    };
  },
})
export default class Hostel extends Vue {
  public get breadcrumbs(): BreadcrumbInterface[] {
    return [
      {
        title: 'Каталог',
        to: '/catalog',
      },
      {
        title: this.$route.meta?.breadCrumb.replace('{{title}}', this.hostel.title),
        active: true,
      },
    ];
  }

  public get hostel(): HostelInterface {
    return hostels.find(hostel => hostel.slug === this.$route.params.slug) as HostelInterface;
  }

  public get services(): AdditionalServiceInterface[] {
    return services.filter(service => this.hostel.services.includes(service.id));
  }

  public get hasCoords(): boolean {
    return this.hostel && this.hostel.coords && this.hostel.coords.length === 2;
  }

  public get markers(): MarkerItem[] {
    return [{
      title: this.hostel.title,
      coords: this.hostel.coords,
    }];
  }

  public get balloonTemplate(): string {
    return `
      <h5>${this.hostel.title}</h5>
      <p>Наши координаты: ${this.hostel.coords}</p>
      <img src="${this.hostel.images[0]}">
    `;
  }

  private beforeCreate(): void {
    if (!hostels.find(hostel => hostel.slug === this.$route.params.slug)) {
      this.$router.replace({
        name: 'NotFound',
      });
    }
  }
}
