











import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import type {
  HostelInterface,
} from '@/types';

@Component
export default class Description extends Vue {
  @Prop({ type: String, required: true })
  public readonly description!: HostelInterface['description'];

  @Prop({ type: Boolean, default: false })
  public readonly hideTitle!: boolean;
}
