








import {
  Component,
  Vue,
} from 'vue-property-decorator';

@Component
export default class CheckInformation extends Vue {
  public get contactEmail(): string {
    return process.env.VUE_APP_CONTACT_EMAIL;
  }
}
