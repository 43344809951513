







import {
  Component,
  Vue,
} from 'vue-property-decorator';

@Component
export default class Comments extends Vue {
  public commentsCount = 0;

  private mounted(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    window.AnyComment = window.AnyComment || { Comments: [], CommentStream: [] };
    window.AnyComment.Comments.push({
      root: 'hostel-comments',
      counter: function(count) {
        that.commentsCount = count;
      },
      app_id: 2510,
      language: 'ru',
      page_url: `${window.location.origin}${this.$route.path}`,
    });

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://widget.anycomment.io/comment/embed.js';

    const head = document.querySelector('head') as HTMLHeadElement;

    head.appendChild(script);
  }
}
