


















import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import type {
  AdditionalServiceInterface,
} from '@/types';

@Component
export default class Services extends Vue {
  @Prop({ type: Array, required: true })
  public readonly services!: AdditionalServiceInterface[];

  @Prop({ type: Boolean, default: false })
  public readonly hideTitle!: boolean;
}
